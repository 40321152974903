<template>
  <div id="VIPlevel">
    <span>会员等级基本信息</span>

    <div class="VIPcard">
      <div class="VIPInfo">
        <span class="nickName">等级名称:<el-input v-model="nickName"
                    placeholder="请输入内容"></el-input>
        </span>
        <span class="bg_level">
          <div style="display:inline-block;width:100px">等级背景:</div>
          <el-radio style="margin-bottom:10px"
                    v-model="radio"
                    label="1">背景色
            <colorPicker class="color"
                         v-model="color" />
          </el-radio>
          <el-radio style="display:block;margin-left: 100px;"
                    v-model="radio"
                    label="2">背景图</el-radio>
          <div class="uploadImg">
            <el-upload action="#"
                       list-type="picture-card"
                       :on-change="handleChange"
                       :on-remove="handleRemove"
                       :auto-upload="false">
              <i slot="default"
                 class="el-icon-plus"></i>
              <!-- <div slot="file"
                   slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail"
                     :src="file.url"
                     alt="">
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file,)">
                    <i class="el-icon-download"></i>
                  </span>
                  <span v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file,)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div> -->
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%"
                   :src="dialogImageUrl"
                   alt="" />
            </el-dialog>
            <span style="color:#eaeaea;font-size: 12px; margin-top: 10px;">尺寸:1000*525像素,小于1M,支持jpg,png,jpeg格式</span>
          </div>
        </span>
        <span class="bg_level">当前级别:
          <el-dropdown trigger="click">
            <el-button type="primary">
              选择等级<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>一级会员</el-dropdown-item>
              <el-dropdown-item>二级会员</el-dropdown-item>
              <el-dropdown-item>三级会员</el-dropdown-item>
              <el-dropdown-item>四级会员</el-dropdown-item>
              <el-dropdown-item>五级会员</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <span class="bg_level">升级条件: 成长值 <el-input style="display: inline-block; width: 150px; margin-left: 10px;"
                    v-model="input_xp"
                    placeholder="请输入内容"></el-input><span style="display:inline-block;margin-left: 5px; color:#989898 ;">成长值对应1:1的消费金额,即3000成长值=消费3000元</span></span>
        <div class="bg_level">
          <span style="display:block"> 权益礼包</span>
          <!-- 酒店 -->
          <span style="display:inline-block">

            <el-checkbox v-model="hotel"
                         label="酒店权益"></el-checkbox>
            <div class="checkboxList">
              <el-checkbox-group v-model="HotelList">
                <el-checkbox label="折扣">优享折扣<el-input class="checkboxList_checkbox"
                            v-model="Hoteldiscounts"
                            style="width:64px; "></el-input>折
                </el-checkbox>
                <el-checkbox label="早餐数量">免费早餐
                  <el-input class="checkboxList_checkbox"
                            v-model="breakfast"
                            style="width:64px; "></el-input>份
                </el-checkbox>
                <el-checkbox label="优惠卷数量">送优惠卷
                </el-checkbox>
                <el-button class="checkboxList_btn"
                           type="text">选择优惠卷</el-button>
                <span class="checkboxList_btn"
                      style="font-size: 14px; color: #ff9c06; ">新客入住 10元现金卷<el-button class="del"
                             type="text"
                             color='red'>删除</el-button>
                </span>
                <el-checkbox label="延迟退房"></el-checkbox>
                <el-checkbox label="酒店免费取消"></el-checkbox>

              </el-checkbox-group>
            </div>

          </span>
          <span style="display:inline-block;margin-left:100px;">
            <!-- 商城 -->
            <el-checkbox v-model="shop"
                         label="商城权益"></el-checkbox>
            <div class="checkboxList">
              <el-checkbox-group v-model="shopList">
                <el-checkbox label="折扣">优享折扣<el-input class="checkboxList_checkbox"
                            v-model="shopDiscounts"
                            style="width:64px; "></el-input>折
                </el-checkbox>
                <el-checkbox label="早餐数量">消费送积分
                  <el-input class="checkboxList_checkbox"
                            v-model="shop_integral"
                            style="width:64px; "></el-input>比例
                </el-checkbox>
                <el-checkbox label="优惠卷数量">送优惠卷
                </el-checkbox>
                <el-button class="checkboxList_btn"
                           type="text">选择优惠卷</el-button>
                <span class="checkboxList_btn"
                      style="font-size: 14px; color: #ff9c06; ">新客入住 10元现金卷<el-button class="del"
                             type="text"
                             color='red'>删除</el-button>
                </span>
                <el-checkbox label="积分兑换"></el-checkbox>
                <el-checkbox label="会员包邮"></el-checkbox>

              </el-checkbox-group>
            </div>

          </span>
          <span style="display:inline-block;margin-left:100px;">
            <!-- 旅游 -->
            <el-checkbox v-model="travel"
                         label="旅游权益"></el-checkbox>
            <div class="checkboxList">
              <el-checkbox-group v-model="travelList">
                <el-checkbox label="折扣">优享折扣<el-input class="checkboxList_checkbox"
                            v-model="travelDiscounts"
                            style="width:64px; "></el-input>折
                </el-checkbox>
                <el-checkbox label="早餐数量">消费送积分
                  <el-input class="checkboxList_checkbox"
                            v-model="travelList_integral"
                            style="width:64px; "></el-input>比例
                </el-checkbox>
                <el-checkbox label="优惠卷数量">送优惠卷
                </el-checkbox>
                <el-button class="checkboxList_btn"
                           type="text">选择优惠卷</el-button>
                <span class="checkboxList_btn"
                      style="font-size: 14px; color: #ff9c06; ">新客入住 10元现金卷<el-button class="del"
                             type="text"
                             color='red'>删除</el-button>
                </span>
                <el-checkbox label="积分兑换"></el-checkbox>
                <el-checkbox label="行程免费取消"></el-checkbox>

              </el-checkbox-group>
            </div>

          </span>
          <div class="update">
            <span style="display:block"> 升级礼包</span>
            <el-checkbox style="margin-left:20px"
                         v-model="update">送优惠卷</el-checkbox>
            <el-button style="display:block;margin-left: 40px;"
                       type="text">选择优惠卷</el-button>
            <span class="checkboxList_btn"
                  style="font-size: 14px; color: #ff9c06;margin-left: 40px; ">新客入住 10元现金卷<el-button class="del"
                         type="text">删除</el-button>
            </span>
          </div>
        </div>

      </div>
      <div class="levelCard">
        <div class="user_Card"
             :style="user_Card_bg">
          <img :class="form.img == '' ? '' : 'user_Card'"
               :src="form.img"
               alt="" />
          <div class="nickName">普通会员</div>
          <div class="level_xp">135 经验值</div>
          <div class="underline"></div>
          <div class="level_bottom">再获得...</div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFile } from '@/api/aliyunOss'
export default {
  data () {
    return {
      color: '#75b06d',
      nickName: null,
      radio: '1',
      fileList: [],
      //酒店权益
      hotel: null,
      HotelList: [],
      Hoteldiscounts: null,//优享折扣
      breakfast: null,
      checkList: null,
      //商城权益
      shop: null,
      shopList: [],
      shopDiscounts: null,
      shop_integral: null,
      //旅游
      travel: null,
      travelList: [],
      travelDiscounts: null,
      travelList_integral: null,
      //升级礼包
      update: null,

      dialogImageUrl: '',
      user_Card_bg_defined: false,
      dialogVisible: false,
      disabled: false,
      form: null,
      licenseFd: null,
      input_xp: null,
      form: {
        img: null
      }
    }
  },
  created () { },
  methods: {
    handleChange (file, fileList) {
      console.log(file, fileList)
      let fd = new FormData()
      fd.append('file', file.raw)
      this.form.img = URL.createObjectURL(file.raw)
      this.licenseFd = fd
      console.log(this.form, 'form')
      console.log(this.licenseFd, 'licenseFd')
      // console.log(file, fileList);
      // this.fileList = fileList.slice(-3);
    },
    limitFileWH (E_width, E_height, file) {
      console.log('限制图片尺寸')
      let _this = this
      let imgWidth = ''
      let imgHight = ''
      const isSize = new Promise(function (resolve, reject) {
        let width = E_width
        let height = E_height
        let _URL = window.URL || window.webkitURL
        let img = new Image()
        img.onload = function () {
          imgWidth = img.width
          imgHight = img.height

          let valid = img.width <= width && img.height <= height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return true
        },
        () => {
          if (imgWidth > 1280 && imgHight > 808) {
            _this.$message.warning({
              message:
                '上传文件的图片大小不合符标准,宽需要为' +
                E_width +
                'px，高需要为' +
                E_height +
                'px。当前上传图片的宽高分别为：' +
                imgWidth +
                'px和' +
                imgHight +
                'px',
              btn: false
            })
            return false
          }
        }
      )
      return isSize
    },
    //图片上传成功操作
    uploadSuccess (item, url) {
      this.$message({
        message: '图片上传成功',
        type: 'success'
      })
      console.log(item, url)
      console.log(this.form)
      // if (item.type === 'upload') {
      //   this.form[item.model] = url;
      // } else if (item.type === 'uploadList') {
      //   this.form[item.model].push(url)
      // } else {
      //   console.log("出错了！")
      // }
    },
    handleDownload (file) {
      console.log(file)
    },
    async uploadFile (param, file) {
      console.log(param, file)
      return new Promise((resolve, reject) => {
        console.log(44)
        console.log(resolve, reject)
        // const image = param.file
        // const isSzie = image.size / 1024 < item.maxSize || 100;
        // if (!isSzie) {
        //   param.onError("上传图片大小不能超过 " + item.maxSize || 100 + "KB!")
        //   return;
        // }
        //调用[限制图片尺寸]函数
        console.log(54)
        this.limitFileWH(item.imgHeight, item.imgWidth, image).then(
          async res => {
            console.log(123)
            const isFlag = res
            console.log(item.imgHeight)
            if (isFlag) {
              await uploadFile(image).then(res => {
                this.uploadSuccess(item, res)
              })
              return resolve(item, res)
            } else if (item.imgWidth > 1280 && item.imgHeight > 808) {
              return reject('上传失败')
            }
          }
        )
      })
    },
    handlePreview () { },
    //图片上传失败操作
    uploadError () {
      console.log('上传失败')
      // this.$message.error(err);
    },
    handleRemove (file) {
      console.log(55)
      console.log(file)
      this.form.img = ''
      file.url = ''
    },

    handlePictureCardPreview (file) {
      console.log(123)
      console.log(file)
      this.dialogImageUrl = file.url
      console.log(this.dialogImageUrl)
      this.dialogVisible = true
    }
  },
  //限制图片尺寸

  computed: {
    user_Card_bg () {
      return {
        background: this.color,
        // backgroundImage: 'url(' + require("@/assets/img/1a3c743970547d43fdca33ec4640018.png") + ')',
        // backgroundImage: this.form.img,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 颜色选择器
/deep/ .m-colorPicker {
  position: absolute !important;
  top: -50%;
}

/deep/ .m-colorPicker .colorBtn[data-v-29accc04] {
  width: 40px;
  height: 20px;
}

.VIPcard {
  margin-top: 20px;
  width: 100%;
  height: 800px;
  // background-color: antiquewhite;

  .VIPInfo {
    .update {
      .del {
        color: red;
        margin-left: 20px;
      }
    }
    .uploadImg {
      margin-left: 120px;
      margin-top: 10px;

      /deep/ .el-upload--picture-card {
        width: 60px;
        height: 60px;
        background-color: #eaeaea;
        position: relative;

        i {
          position: absolute;
          font-size: 20px;
          top: 30%;
          left: 30%;
          color: white;
        }
      }
    }

    float: left;
    width: 50%;
    height: 400px;

    // background-color: cornflowerblue;
    span {
      display: block;
      // margin-top: 10px;
      margin-bottom: 20px;
    }

    .nickName {
      .el-input {
        width: 200px;
        margin-left: 10px;
      }
    }

    .bg_level {
      .color {
        padding: 2px 5px;
        border: 2px solid #dfe1e5;
        border-radius: 5px;
        margin-left: 10px;
      }

      .el-radio {
      }
      /deep/ .el-dropdown {
        .el-button--primary {
          background-color: white;
          border-color: #bfc3cb;
          color: black;
        }
      }
    }
    .checkboxList {
      .del {
        color: red;
        margin-left: 20px;
      }
      .checkboxList_btn {
        margin-left: 40px;
        margin-top: 10px;
        padding-top: 0;
        padding-bottom: 0;
      }
      /deep/ .el-input__inner {
        height: 32px !important;
      }
      .el-checkbox-group {
        .el-checkbox {
          display: block;
          margin-left: 20px;
        }
      }

      /deep/ .el-checkbox {
        margin-top: 20px;
        .el-input {
          margin: 0 7px;
        }
        color: black !important;
        // margin-bottom: 28px;
      }
    }
  }

  .levelCard {
    float: right;
    width: 50%;
    height: 400px;

    .user_Card {
      width: 340px;
      height: 185px;
      border-radius: 20px;
      position: relative;

      .nickName {
        position: absolute;
        // display: inline;
        color: white;
        top: 20px;
        left: 38px;
        font-size: 20px;
      }

      .level_xp {
        top: 72px;
        left: 38px;
        font-size: 16px;
        position: absolute;
        color: white;
      }

      .underline {
        border-block-start: white;
        position: absolute;
        bottom: 52px;
        width: 272px;
        left: 31px;
        border-width: 1.5px 0 0 0;
        border-style: solid;
      }

      .level_bottom {
        color: white;

        position: absolute;
        left: 38px;
        bottom: 18px;
      }
    }

    .user_Card_bg_defined {
      width: 400px;
      height: 200px;
      border-radius: 20px;
      background: url("../../../assets/img/1a3c743970547d43fdca33ec4640018.png")
        no-repeat;
      background-size: 100%;
    }
  }
}
</style>
